@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

@mixin homeCard_mixin {
  color: var(--primary-color);
  font-family: Gilroy;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.homeCard_main {
  display: flex;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.35);
  border-radius: 15.476px;
  height: 11.99406rem;
  max-width: 526.19px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 2rem 1.5rem 2rem 2rem;
  cursor: pointer;
  @include homeCard_mixin;
  @include media('<=phone') {
    height: 8rem;
    font-size: 1rem;
    box-shadow: 0px 0px 21.667px 0px rgba(0, 0, 0, 0.35);
    border-radius: 0.625rem;
    padding: 1rem 1.5rem 1rem 2rem;
  }
  & img {
    height: 100%;
    object-fit: contain;
    object-position: right;
    width: 12.67113rem;
    @include media('<=phone') {
      width: 8rem;
    }
  }
  & .windLeftIcon_wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    left: -7rem;
    top: -6.1rem;
    @include media('<=phone') {
      top: -4.1rem;
      left: -3rem;
    }
    & img {
      width: 10.15625rem;
      height: 1.74106rem;
      flex-shrink: 0;
      @include media('<=phone') {
        width: 6.5625rem;
        height: 1.2rem;
        object-fit: cover;
      }
      &:first-child {
        transform: translate(-1rem);
      }
    }
  }
  & .windRightIcon_wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    right: -7rem;
    top: -6.1rem;
    @include media('<=phone') {
      top: -4.1rem;
      right: -3rem;
    }
    & img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 10.15625rem;
      height: 1.74106rem;
      flex-shrink: 0;
      &:first-child {
        transform: scaleX(-1) translate(-1rem);
      }
      @include media('<=phone') {
        width: 6.5625rem;
        height: 1.2rem;
        object-fit: cover;
      }
    }
  }
  & .secondBoxLeft {
    left: -7rem;
    bottom: -7.1rem;
    top: auto;
    @include media('<=phone') {
      bottom: -5.1rem;
      left: -3rem;
    }
  }
  & .secondBoxRight {
    right: -7rem;
    top: auto;
    bottom: -7.1rem;
    @include media('<=phone') {
      bottom: -5.1rem;
      right: -3rem;
    }
  }
}
