@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.OrderPage_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  gap: 30px;
  // overflow-y: auto;
  & header {
    display: flex;
    justify-content: space-between;
    & span {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      & span {
        border-radius: 0.5625rem;
        background: var(--primary, #ff9500);
        color: #fff;
        width: 3.0625rem;
        height: 2.1875rem;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  & .navigation_button {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    justify-self: flex-end;
    bottom: 0px;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    & button {
      display: inline-flex;
      padding: 0.825rem 1.5rem;
      align-items: flex-start;
      gap: 0.75rem;
      text-align: center;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      justify-content: center;
      cursor: pointer;
      width: 100%;
      @include media('<=phone') {
        width: fit-content;
        font-size: 0.75rem;
        padding: 0.625rem 1.25rem;
      }
      &:first-child {
        border-radius: 0.625rem;
        border: 1px solid #26a62b;
        background: #fff;
        color: #26a62b;
      }
      &:last-child {
        border-radius: 0.625rem;
        background: #26a62b;
        color: #fff;
        border: none;
      }
    }
  }
}

.cancel_button {
  border: 1px solid #a62626 !important;
  background: #fff;
  color: #a62626 !important;
}

.status_wrapper {
  display: flex;
  justify-content: center;
}
