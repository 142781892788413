@import '../utils/variables.module';

.EmployeeCard_wrapper {
  width: 300px;
  min-height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  // border: 1px solid black;
  background-color: white;
  border-radius: 0.625rem;
  overflow: hidden;
  min-height: 375px;
  cursor: pointer;
  & header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    & span {
      height: 2rem;
      width: 7rem;
      display: grid;
      place-items: center;
      &:last-child {
        width: fit-content;
      }
    }
    & .employee_active {
      background-color: #68ffae33;
      border: 1px solid #aed5c0;
      color: #1e8950;
    }
    & .employee_not_active {
      background-color: #ff686833;
      border: 1px solid #ff6868;
      color: #ff6868;
    }
  }
  & .profile_pic_wrapper {
    height: fit-content;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    & svg {
      width: 80px;
      height: 80px;
      border: 1px solid var(--primary, #ff9500);
      border-radius: 50%;
      object-fit: cover;
    }
    & img {
      width: 80px;
      height: 80px;
      border: 1px solid var(--primary, #ff9500);
      border-radius: 50%;
      object-fit: cover;
    }
    & span {
      margin-top: 1rem;
      font-weight: 700;
      font-family: Open Sans;
      font-size: 1rem;
      &:last-child {
        margin-top: 0.7rem;
        font-weight: 300;
      }
    }
  }
  & .card_seprator {
    // height: 1rem;
    border-top: 1px dashed #ababab;
    margin-bottom: 15px;
  }
  & .initial_wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.data_display_wrapper {
  display: flex;
  gap: 1rem;
  & span:first-child {
    font-family: Open Sans;
    font-size: 1rem;
    font-weight: 400;
    line-height: 16.34px;
    // text-align: center;
    color: #a09b9b;
  }
  & span:last-child {
    font-family: Open Sans;
    font-size: 1rem;
    font-weight: 400;
    line-height: 16.34px;
    text-align: right;
    word-break: break-all;
  }
}

.employee_details_wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

._last_login_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #a09b9b;
  margin-top: 1rem;
}
