@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.BillingCard_main {
  width: 100%;
  border-radius: 0.625rem;
  min-height: 6.1875rem;
  max-height: fit-content;
  flex-shrink: 0;
  border: 0.5px solid #fff;
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.3);
  @include media('<=phone') {
    padding: 1.3rem;
  }
  & header {
    display: flex;
    justify-content: space-between;
    & h3 {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @include media('<=phone') {
        font-size: 0.75rem;
      }
    }
  }
  & .bill_container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    & .total_bill {
      display: flex;
      justify-content: space-between;
      & h4 {
        color: #000;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    & footer {
      color: rgba(0, 0, 0, 0.7);
      font-family: Gilroy;
      font-size: 0.825rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @include media('<=phone') {
        font-size: 0.625rem;
      }
    }
    & .order_list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 0.5rem;
      & .order_item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
      }
      & .tax_container {
        padding-bottom: 1rem;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
        margin-bottom: 0.5rem;
      }
    }
  }
}
