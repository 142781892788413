@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.ContinueOrder_main {
  display: flex;
  border-radius: 0.625rem;
  background: var(--primary, #ff9500);
  gap: 0.5rem;
  padding: 0.95rem 1.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include media('<=phone') {
    padding: 0.75rem 1.25rem;
  }
  h3 {
    color: #fff;
    text-align: center;
    font-family: Gilroy;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @include media('<=phone') {
      font-size: 0.75rem;
    }
  }
  .arrowImag {
    height: 15px;
  }
}
