@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.orderPage_card_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2.5rem;
  border-radius: 0.625rem;
  width: 100%;
  border: 0.5px solid #fff;
  background: #fff;
  margin-top: 1.3rem;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.3);
  @include media('<=phone') {
    padding: 1rem;
  }

  & .details {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    & .time {
      display: flex;
      gap: 1rem;
      color: #000;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    & .price {
      display: flex;
      gap: 0.5rem;
    }
  }
  & .instructions_div {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    & h3 {
      color: var(--primary, #ff9500);
      font-family: Gilroy;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    & p {
      margin-top: 0.5rem;
      color: #000;
      font-family: Gilroy;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  & .rate_container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    & h4 {
      color: #000;
      font-family: Gilroy;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    & .img_wrapper {
      margin-top: 1rem;
      display: flex;
      gap: 1rem;
      justify-content: center;
      & img {
        width: 1.8125rem;
        height: 1.8125rem;
        flex-shrink: 0;
      }
    }
  }
  & .navigation_button {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    justify-self: flex-end;
    bottom: 0px;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    & button {
      display: inline-flex;
      padding: 0.825rem 1.5rem;
      align-items: flex-start;
      gap: 0.75rem;
      text-align: center;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      justify-content: center;
      width: 100%;
      cursor: pointer;
      @include media('<=phone') {
        width: fit-content;
        font-size: 0.75rem;
        padding: 0.625rem 1.25rem;
      }
      &:first-child {
        border-radius: 0.625rem;
        border: 1px solid #26a62b;
        background: #fff;
        color: #26a62b;
      }
      &:last-child {
        border-radius: 0.625rem;
        background: #26a62b;
        color: #fff;
        border: none;
      }
    }
  }
}

.CANCELLED {
  filter: grayscale(80%);
}

.OrderItem_wrapper_div {
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  & .order_header {
    display: flex;
    justify-content: space-between;
    & .cookingOrCooked_wrapper {
      display: flex;
      gap: 1rem;
    }
    & img {
      margin-right: 5px;
    }
  }
  & .customised_Items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    margin-top: 0.3rem;
    & img {
      height: 1rem;
      transform: rotate(270deg);
    }
    & h5 {
      width: 8rem;
      color: #000;
      font-family: Gilroy;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  & .customised_Items_wrapper_list {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
    list-style: none;
    margin-left: 0.5rem;
    justify-content: center;
    gap: 0.3rem;
    & img {
      height: 0.8rem;
    }
    & li {
      display: flex;
      gap: 0.2rem;
      align-items: center;
      color: #000;
      font-family: Gilroy;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &:first-child {
        margin-top: 0.3rem;
      }
    }
  }
}

.horizontal_rulling {
  margin-top: 0.75rem;
  min-height: 0.5px;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.total_price_toolpit {
  align-self: flex-end;
}

.progressContainer {
  margin: 20px 0;
  text-align: center;
}

.progressBar {
  position: relative;
  width: 100%;
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  position: absolute;
  height: 100%;
  background: linear-gradient(90deg, #e1e4e1, #4abe7a);
  transition: width 0.4s ease-in-out;
}

.progressStages {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 14px;
  color: #666;
}

.progressStages span {
  position: relative;
}

.progressStages span.activeStage {
  font-weight: bold;
  color: #4caf50;
}

.OrderItemCard_header {
  display: flex;
  justify-content: space-between;
  & span {
    color: #000;
    font-family: Gilroy;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    & span {
      border-radius: 0.5625rem;
      background: var(--primary, #ff9500);
      color: #fff;
      width: 3.0625rem;
      height: 2.1875rem;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.statusPaid {
  // color: #26a62b;
  img {
    width: 50px;
  }
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.elapsedTime {
  font-size: 14px;
  color: #ff0000; // Highlight in red to indicate urgency
  margin-top: 10px;
}

.footerAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  & svg {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
  & span {
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .orderAssignedTo {
    display: flex;
    align-items: center;
    gap: 10px;
    float: left;
  }
  .cancelOrderButton {
    background: #d49191;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .cancelOrderButton:hover {
    background: #ff0000;
  }
}

.billingCard_header {
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}
