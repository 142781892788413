@import '../utils/variables.module';

.OrderHistoryPage_main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h4 {
    text-align: center;
    color: #000;
    font-family: Gilroy;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 1rem;
  }
}

.OrderHistoryPage_main_fill {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & .OrderHistoryPage_order_card {
    display: flex;
    & .oneSide {
      display: flex;
      flex: 1;
      flex-direction: column;
      header {
        display: flex;
        gap: 0.4rem;
        margin-bottom: 0.2rem;
        & strong {
          color: #000;
          font-family: Gilroy;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      & .list_alternative {
        display: flex;
        color: #676767;
        font-family: Gilroy;
        font-size: 0.725rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.015rem;
        margin: 0.3rem 0rem;
      }
      p {
        margin-top: 0.5rem;
      }
      & .list_alternative_span {
        margin: 0.3rem 0rem;
        color: #676767;
        font-family: Gilroy;
        font-size: 0.725rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.015rem;
        margin-left: 0.2rem;
      }
      & ul {
        list-style: none;
        color: #000;
        font-family: Gilroy;
        font-size: 0.725rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.015rem;
        gap: 0.3rem;
        display: flex;
        flex-direction: column;
        & li {
          width: 10rem;
          align-items: center;
          display: flex;
          justify-content: space-between;
          gap: 0.2rem;
          & img {
            width: 0.75rem;
            height: 0.75rem;
          }
          & span {
            display: flex;
            align-items: center;
            gap: 0.3rem;
          }
        }
      }
    }
    & .twoSide {
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & ul {
        list-style: none;
        color: #000;
        font-family: Gilroy;
        font-size: 0.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 0.3rem;
        text-align: right;
      }
      & button {
        display: inline-flex;
        padding: 0.625rem 1.25rem;
        align-items: flex-start;
        gap: 0.75rem;
        border-radius: 0.625rem;
        background: #26a62b;
        border: none;
        color: #fff;
        text-align: center;
        font-family: Gilroy;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
}

.horizontal_rulling {
  margin: 0.2rem 0rem;
  min-height: 0.5px;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
