.orderItem_main {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.2rem;
  margin-top: 1.25rem;
  & .price_wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    gap: 1rem;
  }
  & .orderItem_wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 3rem;
  }
  & .orderItem_part_one {
    display: flex;
    flex-direction: column;
    flex: 1;
    & p {
      align-items: center;
      color: #000;
      font-family: Gilroy;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      width: 7rem;
      & img {
        height: 0.4125rem;
      }
    }
    & img {
      width: 1.0625rem;
      height: 1.0625rem;
    }
    color: #000;
    font-family: Gilroy;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    & span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  & .orderItem_part_two {
    display: flex;
    & .orderItem_button_wrapper {
      width: 6.1875rem;
      height: 2.1875rem;
      flex-shrink: 0;
      border-radius: 0.625rem;
      border: 1px solid #c5c5c5;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.2rem 0.75rem;
      & button {
        all: unset;
        font-size: 2rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:first-child {
          padding-bottom: 0.2rem;
        }
      }
    }
  }
  & .customizedItem {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    color: #000;
    font-family: Gilroy;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.015rem;
    & span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & span {
        gap: 0.5rem;
        &:last-child {
          gap: 0rem;
          min-width: 3rem;
          accent-color: var(--primary, #ff9500);
        }
      }
    }
  }
}

.horizontal_rulling {
  margin-top: 0.75rem;
  min-height: 0.5px;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
