@import '../utils/variables.module';

.tableNumber_list_ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  & li {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    & .table_icons_and_names {
      display: flex;
      gap: 1rem;
      & img {
        width: 4rem;
        height: 2rem;
      }
      & span {
        display: grid;
        place-items: center;
        color: #000;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .tableIcon {
        width: 70px;
      }
    }
    & .table_details {
      display: flex;
      gap: 0.3rem;
      align-items: center;
      & span {
        display: grid;
        place-items: center;
        color: #000;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      & .numberOfOrder {
        width: 1.5625rem;
        height: 1.5625rem;
        background-color: #fc0000;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        font-family: Gilroy;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

.header_line {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
