@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.DiscountIndexPage_main {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100dvh;
  padding: 3rem;
  @include media('<=phone') {
    padding: 1.3rem;
  }
  & .DiscountIndexPage_main_inner {
    display: flex;
    padding: 2.5rem;
    flex-direction: column;
    border-radius: 0.625rem;
    border: 0.5px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.3);
    flex: 1;
    @include media('<=phone') {
      padding: 1rem;
    }
  }
  & header {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: fit-content;
    margin-bottom: 2rem;
    @include media('<=phone') {
      margin-bottom: 1rem;
    }
    & span {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  & .discount_highlighter {
    color: var(--primary, #ff9500);
  }
  .details_header {
    display: flex;
    justify-content: space-between;
    & span {
      &:first-child {
        font-size: 1rem;
        font-weight: 600;
      }
      &:last-child {
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
