:root {
  --primary: #ff9500;
  --primary-black: #000;
  --footer-primary: #f2f2f2;
}

.primaryColorFill {
  fill: var(--primary, #ff9500);
  //   stroke: var(--primary, #ff9500);
}

.primaryColorStroke {
  stroke: var(--primary, #ff9500);
}
