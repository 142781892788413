@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.main_userDetials_con {
  height: 100dvh;
  width: 100%;
  overflow: auto;
  padding: 3rem;
  animation: 0.3s slide_in ease-in-out forwards;
  transform: translate(25rem);
  display: flex;
  flex-direction: column;
  @include media('<=phone') {
    padding: 1.3rem;
  }
  @keyframes slide_in {
    0% {
      transform: translate(25rem);
    }
    100% {
      transform: translate(0rem);
    }
  }

  & header {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #000;
    font-family: Gilroy;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    & button {
      position: absolute;
      right: 3rem;
      display: inline-flex;
      padding: 0.5rem 1.25rem;
      align-items: flex-start;
      gap: 0.75rem;
      border-radius: 0.625rem;
      border: 1px solid rgba(0, 0, 0, 0.2);
      background: #fff;
      cursor: pointer;
      @include media('<=phone') {
        right: 1.3rem;
      }
    }
  }
  & .main_userDetails_wrapper {
    display: flex;
    border-radius: 0.625rem;
    border: 0.5px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.3);
    flex-direction: column;
    // justify-content: center;
    margin-top: 2rem;
    padding: 10vw 20vw;
    flex: 1;
    @include media('<=phone') {
      margin-top: 1.3rem;
      padding: 1rem;
      max-height: max-content;
    }
    gap: 1rem;
    & label {
      display: flex;
      flex-direction: column;
      color: #000;
      // font-family: Gilroy;
      // font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      gap: 0.5rem;
      // height: 3rem;
      & span {
        display: flex;
        align-items: center;
        border: 1px solid rgba(228, 201, 201, 0.8);
        &:focus {
          // outline: 1px solid var(--primary, #ff9500);
          outline: none;
          border-radius: 0.625rem;
        }
      }
      & input {
        width: 100%;
        flex: 1;
        // z-index: 1;
        border: none;
        background: #fff;
        padding: 0.7rem;
        // border-radius: 0.625rem;
        &::placeholder {
          color: rgba(0, 0, 0, 0.3);
        }
        &:focus {
          outline: none;
          // outline: 1px solid var(--primary, #ff9500);
        }
      }
    }
    & img {
      padding: 4.5rem;
    }
  }
}

.continue_order_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.3rem;
  gap: 0.5rem;
  align-items: center;

  @include media('<=phone') {
    justify-content: center;
  }

  & img {
    height: 0.8rem;
    transform: rotate(180deg);
    padding: 0px !important;
  }
  & button {
    border-radius: 0.625rem;
    background: var(--primary, #ff9500);
    display: inline-flex;
    width: 100%;
    padding: 0.85rem 1.5rem;
    align-items: flex-start;
    justify-content: center;
    gap: 0.75rem;
    color: #fff;
    border: none;
    cursor: pointer;
    @include media('<=phone') {
      margin-top: 1.3rem;
      padding: 1rem;
      width: fit-content;
    }
  }
}
