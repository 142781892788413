.App {
  display: flex;
  min-height: 100dvh;
  position: relative;
  justify-content: center;
  & .app_wrapper {
    display: flex;
    max-width: 1024px;
    width: 100%;
    // position: relative;
  }
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

p {
  font-family: Gilroy;
}
