@import '../../../styles/utils/breakPoints.module.scss';
@import '../../../styles/utils/variables.module';

.ItemMenuIndex_main {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  width: 100%;

  @include media('<=phone') {
    // padding: 0px 20px;
  }
}

.logoHeader {
  display: flex;
  padding: 1rem;
  height: fit-content;
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  @include media('<=phone') {
    margin-top: 0rem;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    margin-bottom: 0px;
  }
  & .img_back_logo {
    left: 0px;
    position: absolute;
  }
}
