@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.cart_main {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 1.3rem;
  flex: 1;
  max-height: 100dvh;
  overflow-y: auto;
  animation: 0.3s slide_in ease-in-out forwards;
  transform: translate(25rem);
  width: 100%;
  @include media('<=phone') {
    padding: 1.3rem;
  }
  @keyframes slide_in {
    0% {
      transform: translate(25rem);
    }
    100% {
      transform: translate(0rem);
    }
  }
  & header {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: fit-content;

    & span {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    svg {
      cursor: pointer;
    }
  }
  & .selected_item_wrapper {
    padding: 2rem;
    min-width: 100%;
    height: fit-content;
    border-radius: 0.625rem;
    border: 0.5px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.3);
    @include media('<=phone') {
      padding: 1rem 1rem 1.3rem 1rem;
    }
    & h3 {
      color: #000;
      font-family: Gilroy;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    & .addd_instructions_btn {
      display: flex;
      height: 2.5625rem;
      flex-shrink: 0;
      border-radius: 0.625rem;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background: #fff;
      width: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    & .add_items_btn {
      all: unset;
      color: #26a62b;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
      margin-top: 1.3rem;
      cursor: pointer;
    }
  }
  & .discount_wrapper {
    display: flex;
    border-radius: 0.625rem;
    border: 0.5px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.3);
    padding: 2rem;
    flex-direction: column;
    @include media('<=phone') {
      padding: 1.3rem;
    }
    & .discount_highlighter {
      color: var(--primary, #ff9500);
    }
    header {
      display: flex;
      justify-content: space-between;
      flex: 1;
      & span {
        display: flex;
        gap: 0.3rem;
        &:first-child {
          font-size: 1.2rem !important;
          @include media('<=phone') {
            font-size: 0.75rem !important;
          }
        }
        &:last-child {
          font-family: Gilroy;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          @include media('<=phone') {
            font-size: 0.625rem;
          }
        }
      }
    }
    & .view_all {
      color: var(--primary, #ff9500);
      font-family: Gilroy;
      font-size: 1rem;
      width: fit-content;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 1rem;
      @include media('<=phone') {
        font-size: 0.75rem;
      }
    }
  }
}

.addInstructions_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  & h2 {
    color: var(--primary, #ff9500);
    font-family: Gilroy;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & p {
    margin-top: 0.2rem;
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-wrap: break-word;
  }
}

.addMoreItemWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  align-items: center;
  // justify-content: flex-end;
  justify-content: center;
  @include media('<=phone') {
    justify-content: center;
  }
  & .addMoreItemWrapper_inner {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    max-width: 600px;
    padding: 2.5rem 2rem;
    border-radius: 1.875rem;
    background: #fff;
    height: 50%;
    @include media('<=phone') {
      height: fit-content;
    }
    & h2 {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1rem;
    }
    .button_wrapper {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;

      gap: 1rem;
      & button {
        display: inline-flex;
        padding: 0.625rem 1.4375rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        cursor: pointer;
        &:first-child {
          border-radius: 0.625rem;
          border: 1px solid var(--primary, #ff9500);
          background: #fff;
          color: var(--primary, var(--primary, #ff9500));
        }
        &:last-child {
          border-radius: 0.625rem;
          border: 1px solid var(--primary, #ff9500);
          background: var(--primary, #ff9500);
          color: #fff;
        }
      }
    }
  }
  textarea {
    height: 100%;
    width: 100%;
    padding: 1rem;
    border-radius: 0.625rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    resize: none;
    line-height: 1.25rem; /* 166.667% */
    outline: none;
    @include media('<=phone') {
      height: 17.6875rem;
    }
  }
}
