.receipt_wrapper {
  width: 58mm;
  font-family: Arial, sans-serif;
  font-size: 13px;
  border: 1px dashed #000;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  header,
  .order_info,
  .items_heading,
  .item_row,
  .total_section,
  footer {
    width: 100%;
    text-align: left;
  }

  header {
    text-align: center;
    margin-bottom: 5px;

    span {
      display: block;
    }
  }

  .order_info {
    margin-bottom: 5px;

    span {
      display: block;
    }
  }

  .items_heading,
  .item_row {
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    gap: 2px;
    margin-bottom: 3px;

    span {
      word-break: break-word;
    }
  }

  .total_section {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }

  footer {
    margin-top: 10px;
    text-align: center;

    span {
      display: block;
    }
  }
}
