@import '../utils/variables.module';

.footer_main {
  display: flex;
  width: 100%;
  height: 70px;
  background: var(--footer-primary);
  // padding-top: 1rem;
  justify-content: space-evenly;
  align-items: center;
  .smallIcons {
    width: 1.5rem;
    height: fit-content;
  }
  img {
    &:last-child {
      width: 1.7rem;
      transform: translateY(-0.2rem);
    }
  }
  .home_footer_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 4.125rem;
    // height: 4.125rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--primary, #ff9500);
    transform: translateY(-2.5rem);
    cursor: pointer;
    & img {
      all: unset;
    }
  }
}

.svg_footer_wrapper {
  cursor: pointer;
}

.cart_footer_wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.cartNumber {
  position: absolute;
  background-color: var(--primary, #ff9500);
  color: white;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  top: -15px;
  right: -25px;
}
