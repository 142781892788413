@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.AdminSideBar_wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px;
  .AdminSideBar_main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 29.0625rem;
    flex-shrink: 0;
    background: #fff;
    padding: 4.5rem 4rem;
    gap: 1rem;
    @include media('<=phone') {
      padding: 1rem;
      width: 100vw;
    }
    & header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0 1;
      max-height: fit-content;
      margin-top: 1rem;
      flex-direction: row-reverse;
      & img:first-child {
        // position: absolute;
        height: 1.7rem;
        left: 4rem;
        cursor: pointer;
        @include media('<=phone') {
          left: 1.5rem;
        }
      }
    }
    & ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 1rem;
      & li {
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        // padding-left: 1rem;
      }
    }
  }
  & .open_Admin_sideBar {
    transition: 0.2s ease-in-out;
    animation: sideBarSlideIn 0.25s ease-in-out;
    @keyframes sideBarSlideIn {
      0% {
        transform: translate(-29rem);
      }
      100% {
        transform: translate(0rem);
      }
    }
  }
  & .close_admin_sideBar {
    transition: 0.2s ease-in-out;
    animation: sideBarSlideOut 0.25s ease-in-out;
    @keyframes sideBarSlideOut {
      0% {
        transform: translate(0rem);
      }
      100% {
        transform: translate(-29rem);
      }
    }
  }
}

.AdminSideBar_wrapper_fadeIn {
  animation: sideBarFadeIn 0.25s ease-in-out forwards;
  background: rgba(0, 0, 0, 0.5);
  @keyframes sideBarFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.AdminSideBar_wrapper_fadeOut {
  animation: sideBarFadeOut 0.25s ease-in-out forwards;
  background: rgba(0, 0, 0, 0.5);
  @keyframes sideBarFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      background: transparent;
    }
  }
}

.logo {
  max-height: 38px;
}

.cursorPointer {
  cursor: pointer;
}
