@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.SideMenuButton_wrapper {
  width: 10.51644rem;
  height: 3.76375rem;
  flex-shrink: 0;
  border-radius: 1.107rem 0rem 0rem 1.107rem;
  background: #000;
  position: absolute;
  right: 0px;
  bottom: 12vh;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  transition: 0.2s ease-in-out;
  // cursor: pointer;
  @include media('<=phone') {
    width: 5.9375rem;
    height: 2.125rem;
  }
  & header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1rem 1rem 0rem;
    cursor: pointer;
    & img {
      width: 0.88563rem;
      height: 1.6605rem;
    }
    @include media('<=phone') {
      padding: 0.33rem;
      padding-left: 0px;
      gap: 0.5rem;
      height: fit-content;
      & img {
        height: 1.2rem;
      }
    }
  }
  & span {
    color: #fff;
    font-family: Gilroy;
    font-size: 1.6605rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    @include media('<=phone') {
      font-size: 0.9375rem;
      padding: 0.2rem;
      display: flex;
      align-items: center;
    }
  }
  & ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.2rem;
    overflow: auto;
    margin-right: 0.5rem;
    @include media('<=phone') {
      margin-top: 0.5rem;
      font-size: 0.9375rem;
    }
    li {
      cursor: pointer;
      color: #fff;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @include media('<=phone') {
        font-size: 0.9375rem;
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
      margin-left: 1rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
  & .emptySideMenuBtn {
    height: 18rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Gilroy;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 1rem;
  }
}

.SideMenuButton_wrapper_active {
  height: 20.76063rem;
  width: 17.6875rem;
  right: 2rem;
  border-radius: 0.68294rem;
  padding-bottom: 1rem;
  & span {
    font-size: 1rem;
  }
  & img {
    width: 0.54631rem !important;
    height: 1.02438rem !important;
  }
  @include media('<=phone') {
    width: 16.1875rem;
    height: 19rem;
    right: 1rem;
    padding: 1rem;
  }
}
