@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.logoHeader {
  display: flex;
  // padding: 1rem;
  // padding-top: 0rem;
  height: fit-content;

  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: space-between;
  @include media('<=phone') {
    margin-top: 0px;
    padding: 20px;
    padding-bottom: 0px;
  }
  .logo {
    max-height: 38px;
  }
  & .profileIcon_header {
    cursor: pointer;
    :hover {
      .profile_dropdown {
        display: block;
      }
    }
  }
  &.profileIcon_wrapper {
    display: flex;
    align-items: center;
  }
  & .hamburgerIcon_header {
    cursor: pointer;
    fill: var(--primary, #ff9500);
  }
  .profile_dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    // padding: 10px;
    z-index: 100;
    display: none;
    img {
      width: 30px;
      height: 30px;
    }
    &.active {
      display: block;
    }
    & .profile_dropdown_item {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 10px;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        background-color: rgb(241, 225, 195);
      }
      & .profile_dropdown_icon {
        margin-right: 10px;
      }
    }
  }
}

.cursorPointer {
  cursor: pointer;
}
