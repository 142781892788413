@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.discountList_item_main_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  & .title {
    color: #000;
    font-family: Gilroy;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    @include media('<=phone') {
      font-size: 0.75rem;
    }
  }
  & button {
    padding: 0.75rem 1.25rem;
    text-align: center;
    font-family: Gilroy;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    height: 2rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--primary, #ff9500);
    @include media('<=phone') {
      font-size: 0.75rem;
    }
  }
  & .applied_btn {
    border-radius: 0.625rem;
    background: var(--primary, #ff9500);
    color: #fff;
  }
  & .apply_btn {
    background: #fff;
    color: var(--primary, #ff9500);
  }
}
