@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.main_container_admin_tables {
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 1rem 5rem;
  @include media('<=phone') {
    padding: 1.5rem;
  }
}

.logoHeader {
  display: flex;
  padding: 1rem;
  padding-top: 0rem;
  height: fit-content;
  width: 100%;
  margin-top: 3.5rem;
  margin-bottom: 3rem;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  @include media('<=phone') {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }
  & .profileIcon_header {
    position: absolute;
    right: 0rem;
  }
  & .hamburgerIcon_header {
    position: absolute;
    left: 0px;
  }
}

.TableSelectedOrder_main {
  display: flex;
  flex-direction: column;
}

.add_new_table_wrapper {
  display: flex;
  position: absolute;
  height: 100dvh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  & .inner_wrapper {
    height: fit-content;
    width: fit-content;
    padding: 1.4rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 0.325rem;
    & header {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      align-items: center;
      & img {
        height: 1rem;
      }
    }
  }
  & span {
    // margin-bottom: 1rem;
    display: flex;
    height: 1rem;
    width: 100%;
    margin-bottom: 0.8rem;
    color: red;
  }
  & .input_wrapper {
    display: flex;
    gap: 1rem;
    margin: 0.5rem 0rem 0.2rem;
    & input {
      padding: 1rem;
      &::placeholder {
        color: rgba(0, 0, 0, 0.3);

        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &:first-child {
        width: 8.5rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        border: 1px solid rgba(228, 201, 201, 0.8);
        background: #fff;
      }
      &:last-child {
        border-radius: 0.625rem;
        border: 1px solid rgba(228, 201, 201, 0.8);
        background: #fff;
        width: 8.5rem;
        height: 2.5rem;
        flex-shrink: 0;
      }
    }
  }
  & .button_wrapper {
    display: flex;
    gap: 1rem;
    & button {
      display: flex;
      width: 8.20094rem;
      padding: 0.6875rem 1.8125rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border: none;
      cursor: pointer;
      &:first-child {
        border-radius: 0.625rem;
        border: 1px solid var(--primary, #ff9500);
        background: #fff;
        color: var(--primary, #ff9500);
        text-align: center;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &:last-child {
        border-radius: 0.625rem;
        background: var(--primary, #ff9500);
        color: #fff;
        text-align: center;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

.ChefOrderPage_button_wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  & span {
    display: flex;
    gap: 1rem;
  }
  & button {
    display: inline-flex;
    padding: 0.625rem 1.4375rem;
    justify-content: center;
    align-items: center;
    border: none;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid #ccc;
    background: #fff;
    color: #000;
    text-align: center;
    font-family: Gilroy;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  & .activeBtn {
    border-radius: 0.625rem;
    background: var(--primary, #ff9500);
    color: #fff;
    text-align: center;
    font-family: Gilroy;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
  }
}

.tablePageheader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  & h3 {
    color: #000;
    font-family: Gilroy;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: grid;
    place-items: center;
  }
  & span {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    color: #000;
    font-family: Gilroy;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    & .AddItem_button {
      display: flex;
      bottom: 4rem;
      right: 2rem;
      align-items: center;
      gap: 0.4rem;
      margin-left: 1rem;
      & button {
        display: flex !important;
        height: 2.375rem !important;
        // padding: 0.1875rem 1.1875rem;
        padding: 10px 15px !important;
        align-items: center;
        gap: 0.5rem !important;
        border-radius: 0.625rem;
        background: var(--primary, #ff9500) !important;
        color: #fff;
        text-align: center;
        font-family: Gilroy;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
        cursor: pointer;
      }
    }
  }
}
