@import '../../../utils/variables.module';

.MenuSections_main {
  display: flex;
  width: 100%;
  gap: 0rem;
  flex-direction: column;
  & header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.75rem;
    & span {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  & .horizontal_rulling {
    min-height: 0.5px;
    min-width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  & .menuSection_item_card {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
  }
}
