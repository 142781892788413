@import "../utils/breakPoints.module.scss";
@import "../utils/variables.module";

.TakeAwayIndex_file{
    display: flex;
    min-width: 100%;
    min-height: 100%;
    flex-direction: column;
    & .TakeAwayIndex_main{
        flex: 1;
        padding: 0rem 5rem;
        padding-top: 0rem;
        @include media("<=phone"){
            padding: 1rem;
        }
    }
}


.logoHeader{
    display: flex;
    padding: 1rem;
    height: fit-content;
    width: 100%;
    margin-top: 3.5rem;
    margin-bottom: 3rem;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    @include media("<=phone"){
        margin-top: 0.5rem;
        margin-bottom: 0rem;
    }
}