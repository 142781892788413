@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.categoryIndex_main_con {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & h2 {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-left: 1.5rem;
      margin-bottom: 0.5rem;
    }
    & button {
      border: none;
      display: inline-flex;
      // padding: 0.85rem 1.1875rem;
      padding: 10px 15px;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.625rem;
      background: var(--primary, #ff9500);
      color: #fff;
      text-align: center;
      font-family: Gilroy;
      font-size: 1.0625rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
    }
  }

  & .categoryIndex_inner_con {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    & .horizontal_rulling {
      min-height: 0.5px;
      min-width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  & .categoryIndex_empty {
    display: grid;
    margin-left: 1.5rem;
    grid-template-columns: 5rem 1fr 1fr;
    align-items: center;
    margin-top: 1.5rem;
    gap: 1.5rem;
    & .icon_wrapper {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 0.625rem;
      background: #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      & span {
        width: 3.0625rem;
        height: 3.0625rem;
        border-radius: 0.625rem;
        background: #d7d7d7;
      }
    }
    & .line_wrapper {
      max-width: 25.875rem;
      width: 100%;
      height: 1.5rem;
      flex-shrink: 0;
      border-radius: 0.625rem;
      background: #ebebeb;
    }
    & .dot_wrapper {
      display: grid;
      justify-self: flex-end;
      width: 1.5625rem;
      height: 1.5rem;
      flex-shrink: 0;
      border-radius: 0.625rem;
      background: #ebebeb;
    }
  }
  & .emptyCategoryIndex {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    @include media('<=phone') {
      top: 55%;
    }
    & h3 {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
