@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.chefIndex_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // padding: 3rem;
  padding: 10px;
  @include media('<=phone') {
    padding: 1.3rem;
  }
}

.toggle-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.toggle-button.on {
  background-color: #4caf50;
  color: white;
}

.toggle-button.off {
  background-color: #f44336;
  color: white;
}
.toggle-button {
  border-radius: 50%;
  width: 100px;
}

.logo {
  max-height: 38px;
}

.emptyOrderList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  color: var(--primary);
}

.emptyOrderList svg {
  // width: 150px;
  height: 150px;
  // height: 100px;
}

.ChefOrderPage_button_wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  & span {
    display: flex;
    gap: 1rem;
  }
  & button {
    display: inline-flex;
    padding: 0.625rem 1.4375rem;
    justify-content: center;
    align-items: center;
    border: none;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid #ccc;
    background: #fff;
    color: #000;
    text-align: center;
    font-family: Gilroy;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  & .activeBtn {
    border-radius: 0.625rem;
    background: var(--primary, #ff9500);
    color: #fff;
    text-align: center;
    font-family: Gilroy;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
  }
}
