@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.AdminIndex_main_container_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  overflow-y: auto;
}
.AdminIndex_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  // padding: 1rem 5rem;
  @include media('<=phone') {
    padding: 1.5rem;
  }
}

.employee_card_wrapper {
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  width: 100%;
  padding: 1.5rem 1rem;
  &::-webkit-scrollbar {
    display: none;
  }
  @include media('<=phone') {
    justify-content: center;
  }
  // height: fit-content;
}

.add_new_emp_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  & span {
    font-weight: 600;
    font-family: Gilroy;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & button {
    display: flex;
    height: 2.375rem;
    padding: 0.1875rem 1.1875rem;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: var(--primary, #ff9500);
    color: #fff;
    text-align: center;
    font-family: Gilroy;
    font-size: 1.0625rem;
    font-style: normal;
    // font-weight: 600;
    line-height: normal;
    border: none;
    cursor: pointer;
  }
}

// make scroll bar thin and light
.AdminIndex_main_container_wrapper::-webkit-scrollbar {
  width: 0.25rem;
  background-color: #e4e4e4;
}
.AdminIndex_main_container_wrapper::-webkit-scrollbar-thumb {
  background-color: #969191;
  opacity: 0.5;
  border-radius: 0.25rem;
}
