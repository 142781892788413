@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.add_new_employee {
  // height: 100dvh;
  // width: 100%;
  width: -webkit-fill-available;
  display: flex;
  background-color: white;
  position: relative;
  padding: 10px;
  flex-direction: column;
  gap: 2rem;
  // overflow-y: auto;
  animation: addItem_slideIn 0.25s ease-in-out forwards;
  @include media('<=phone') {
    padding: 0px;
  }
  & header {
    display: flex;
    gap: 1rem;
    cursor: pointer;
    align-items: center;
    max-width: max-content;
    & span {
      font-family: Open Sans;
      font-size: 17px;
      font-weight: 600;
      line-height: 23.15px;
      text-align: left;
    }
  }
}

.add_new_employee_input_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  & span {
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.15px;
    text-align: left;
  }
  & input[type='text'],
  input[type='number'],
  input[type='email'] {
    border: 1px solid #e4c9c9cc;
    height: 40px;
    width: 100%;
    border-radius: 0.35rem;
    padding: 0.5rem;
  }
}

.add_new_employee_textArea_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  & span {
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.15px;
    text-align: left;
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }
  & textarea {
    height: 120px;
    width: 100%;
    border-radius: 0.35rem;
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.15px;
    text-align: left;
    padding: 0.5rem;
    border: 1px solid #e4c9c9cc;
  }
}

.add_new_employee_checkBox_wrapper {
  display: flex;
  gap: 1rem;
}

.subtext_wrapper {
  font-family: Open Sans !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 16.34px !important;
  text-align: left !important;
  color: #00000099 !important;
}

.add_new_employee_img_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & span {
    display: flex;
    gap: 0.3rem;
    align-items: baseline;
  }
  & label {
    height: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e4c9c9cc;
    border-radius: 0.3rem;
    & span {
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: 17.15px;
      color: #00000099;
    }
  }
}

.add_new_emp_button_wraper {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  height: 5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  & button {
    display: flex;
    height: 2.375rem;
    padding: 0.1875rem 1.1875rem;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid var(--primary, #ff9500);
    color: var(--primary, #ff9500);
    text-align: center;
    font-family: Gilroy;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: white;
    cursor: pointer;
    width: 7rem;
    &:last-child {
      color: #fff;
      border: none;
      background: var(--primary, #ff9500);
    }
  }
}

.profile_viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  // position: relative;
  & .existing_profile_pic {
    height: 100px !important;
    width: 100px !important;
    object-fit: cover;
    object-position: center;
    border: 1px solid var(--primary, #ff9500);
    border-radius: 200px;
  }
  & span:last-child {
    font-weight: 400;
    font-family: Open Sans;
    font-size: 1rem;
  }
  & .name_role {
    font-weight: 700 !important;
  }
}

.delete_blockAccess_wrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-family: Open Sans;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.15px;
  text-align: center;
  top: 10px;
  right: 10px;
  & button {
    background: #ff0000;
    border-radius: 0.3rem;
    border: none;
    padding: 0.5rem 1rem;
    color: white;
    cursor: pointer;
  }
  & .unblock_access {
    background: #225d3ac7;
  }
}
.emp_documents_wrapper {
  display: flex;
  flex-direction: column;
  & .header_emp {
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.15px;
    text-align: left;
  }
}

.emp_pdf_inner_wrapper {
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
  & span {
    display: flex;
    flex-direction: column;
    align-items: center;
    & img {
      height: 40px;
      width: 35px;
    }
  }
}
