@import '../utils/variables.module';

.OrderAnimationThree_main {
  display: flex;
  height: fit-content;
  width: fit-content;
  padding: 2rem 0rem;
  position: relative;
  height: 10rem;
  width: 10rem;
  justify-content: center;
  align-items: center;
  & img {
    height: 10rem;
    width: 10rem;
    position: absolute;
  }
}
