@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.homePage_wrapper {
  display: flex;
  height: 100dvh;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  @include media('<=phone') {
    // width: calc(100% - 1.5rem);
    font-size: 1rem;
  }
}

.homePage {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  height: fit-content;
  width: fit-content;
  max-width: 100dvh;

  & .logo {
    max-width: 100px;
    max-height: 100px;
  }

  & .homePageFiller {
    max-width: 30.27531rem;
    width: calc(100% - 3rem);
    height: fit-content;
    @include media('<=phone') {
      width: calc(100% - 1.5rem);
    }
  }
  & .homePage_heading {
    text-align: center;
    // margin-top: 60px;
    font-size: 1.5rem;
    position: relative;
    @include media('<=phone') {
      width: calc(100% - 1.5rem);
      font-size: 1rem;
    }
  }
  & .homePage_card_wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 65px;
    width: 100%;
    align-items: center;
    @include media('<=phone') {
      margin-top: 42px;
      gap: 26px;
    }
  }
}
