@import '../utils/variables.module';

.OrderAnimationTwo_main {
  height: fit-content;
  width: fit-content;
  animation: circleAround 2s linear infinite;
  padding: 2rem 0rem;
  @keyframes circleAround {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
