@import '../../utils/variables.module';

.horizontal_rulling {
  min-height: 0.5px;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.itemCard_main {
  display: flex;
  flex: 1;
  gap: 1.5rem;
  height: fit-content;
  padding-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.625rem;
  align-items: center;
  & .header {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 0.8125rem;
      height: 0.8125rem;
      flex-shrink: 0;
    }
  }
  & .item_details {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;
    & .tag_wrapper {
      display: flex;
      gap: 1rem;
      & .tag_name {
        width: 100%;
        color: var(--primary, #ff9500);
        font-family: Gilroy;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // margin-bottom: 0.35rem;
      }
    }

    & .info {
      display: flex;
      gap: 0.4rem;
      height: fit-content;
      // margin-bottom: 0.5rem;
      align-items: center;

      & .name {
        color: #000;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: fit-content;
      }
      & .rating_wrapper {
        display: flex;
        align-items: center;
        gap: 0.4rem;
      }
      & .rating {
        color: #000;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        height: fit-content;
      }
    }
    & .price {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      gap: 0.4rem;
    }
    & .description {
      color: #000;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      max-width: 500px;

      display: -webkit-box;
      -webkit-line-clamp: 3; /* Limit to 3 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: max-height 0.5s ease;
      // line-height: 0.875rem;
    }
  }
  & .itemIconAndButton {
    display: flex;
    position: relative;
    justify-content: center;
    height: fit-content;
    position: relative;
    // top: -15px;
    & img {
      width: 9.25rem;
      height: 6.1875rem;
      flex-shrink: 0;
      border-radius: 0.625rem;
    }
    & .Edit_icons {
      height: 1.8rem !important;
      width: 1.8rem !important;
    }
    & .addItem_button {
      display: flex;
      position: absolute;
      width: 7.8125rem;
      height: 2.125rem;
      flex-shrink: 0;
      top: 80%;
      border-radius: 0.625rem;
      border: 1px solid var(--primary, #ff9500);
      background: #fff;
      color: var(--primary, #ff9500);
      font-family: Gilroy;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.disable_item {
  filter: grayscale(100%);
}

.opening_popper {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.394);
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerPopper {
  width: 382px;
  border-radius: 10px;
  background-color: white;
  position: relative;
  z-index: 1;
}

.innerPopper_padding {
  padding: 1.5rem;
  padding-top: 2.3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  position: relative;
  & img {
    height: 1rem;
    position: absolute;
    right: 2rem;
    top: 1rem;
  }
}

.button_wrapper_edit {
  display: flex;
  justify-content: center;
  gap: 1rem;
  & button {
    height: 2.75rem;
    width: 8rem;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    &:last-child {
      background-color: #f55757;

      color: white;
    }
    &:first-child {
      border: 1px solid var(--primary, #ff9500);
      color: var(--primary, #ff9500);
      background-color: white;
    }
  }
}

.enable_disable_button_wrapper {
  display: flex;
  // padding-top: 0.3rem;
  align-items: center;
  position: absolute;
  bottom: 80%;
  // justify-content: center;
  & button {
    // margin-left: 1rem;
    letter-spacing: 0.07rem;
    height: 2rem;
    width: 6rem;
    // background: var(--primary, #ff9500);
    border-radius: 0.625rem;
    border: 1px solid transparent;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.enable {
  background-color: #83c980;
}
.disable {
  background-color: #ff0000;
}

.cursorPointer {
  cursor: pointer;
}
