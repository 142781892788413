*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html {
  overflow: hidden;
}
body {
  margin: 0;
  /* overflow: hidden; */
  max-height: 100dvh;
  overflow-y: auto;
}
