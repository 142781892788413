@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.addItemIndex_main {
  display: flex;
  width: 100%;
  height: 100dvh;
  background-color: white;
  position: absolute;
  padding: 3rem 3rem;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
  animation: addItem_slideIn 0.25s ease-in-out forwards;
  @include media('<=phone') {
    padding: 1.5rem;
  }
  @keyframes addItem_slideIn {
    0% {
      transform: translate(100%);
    }
    100% {
      transform: translate(0%);
    }
  }
  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .formInfo {
      display: flex;
      gap: 1rem;
    }
    & button {
      border: none;
      display: flex;
      padding: 10px;
      align-items: center;
      border-radius: 10px;
      background: var(--primary, #ff9500);
      color: #fff;
      text-align: center;
      font-family: Gilroy;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }
  }
  & .header_add_item {
    display: flex;
    justify-content: flex-end;
    & img {
      height: 1rem;
      flex-shrink: 0;
    }
    & h4 {
      color: #000;
      font-family: Gilroy;
      font-size: 1.0625rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  & form {
    padding: 0rem 7.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include media('<=tablet') {
      padding: 0rem 0rem;
    }
    & label {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      cursor: pointer;
      & input {
        display: flex;
        max-width: 42.1875rem;
        width: 100%;
        height: 2.5rem;
        flex-shrink: 0;
        padding: 1rem;
        border-radius: 0.625rem;
        border: 1px solid rgba(228, 201, 201, 0.8);
        background: #fff;
        outline: none;
      }
    }
    & .admin_toggle_wrapper {
      display: flex;
      gap: 1rem;
    }
    & .admin_form_textarea {
      display: flex;
      & header {
        flex-direction: row;
        align-items: center;
      }
      & p {
        color: rgba(0, 0, 0, 0.6);
        font-family: Gilroy;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      & textarea {
        height: 7.5rem;
        border-radius: 0.625rem;
        border: 1px solid rgba(228, 201, 201, 0.8);
        background: #fff;
        padding: 1rem;
        &::placeholder {
          color: rgba(0, 0, 0, 0.3);
          font-family: Gilroy;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    & .doubleInputBox_wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      & header {
        display: flex;
        gap: 1rem;
        align-items: center;
        h3 {
          color: #000;
          font-family: Gilroy;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        p {
          color: rgba(0, 0, 0, 0.6);
          font-family: Gilroy;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      & .input_wrapper_double_box {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr 1fr;
        & input {
          display: flex;
          max-width: 42.1875rem;
          width: 100%;
          height: 2.5rem;
          flex-shrink: 0;
          padding: 1rem;
          border-radius: 0.625rem;
          border: 1px solid rgba(228, 201, 201, 0.8);
          background: #fff;
          outline: none;
        }
        & input:first-child {
          grid-column: 1 / 3;
        }
        & input:last-child {
          grid-column: 3;
        }
      }
      & .input_wrapper_double_box_quantity {
        // display: grid;
        // gap: 1rem;
        // grid-template-columns: 1fr 1fr;
        display: flex;
        gap: 1rem;
        align-items: center;
        flex-wrap: wrap;

        & span {
          display: flex;
        }
        & input {
          display: flex;
          max-width: 42.1875rem;
          // width: 100%;
          flex: 1;
          height: 2.5rem;
          flex-shrink: 0;
          padding: 1rem;
          border-radius: 0.625rem;
          border: 1px solid rgba(228, 201, 201, 0.8);
          background: #fff;
          outline: none;
        }
      }
    }
    & .admin_toggle_veg_nonVeg {
      display: flex;
      width: 2.5rem;
      height: 1.3125rem;
      flex-shrink: 0;
      border-radius: 2.5rem;
      // border: 0.5px solid rgba(0, 0, 0, 0.5);
      background: #fff;
      position: relative;
      justify-content: center;
      align-items: center;
      & button {
        width: 1.8rem;
        height: 0.4375rem;
        flex-shrink: 0;
        border-radius: 2.5rem;
        background: rgba(0, 0, 0, 0.1);
        border: none;
        position: relative;
        transition: 0.25s ease-in-out;
        cursor: pointer;
        & span {
          position: absolute;
          display: flex;
          width: 1.0625rem;
          height: 1.0625rem;
          flex-shrink: 0;
          background-color: black;
          border-radius: 50%;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          background-color: white;
          transition: 0.25s ease-in-out;
        }
        & .vegCircle {
          left: -10%;
        }
        & .nonVegCircle {
          left: 50%;
        }
      }
    }
    & .add_customizable_items {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      max-width: 42.1875rem;
      width: 100%;
      padding: 0.85em 1.1875rem;
      color: var(--primary, #ff9500);
      text-align: center;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 0.625rem;
      background-color: white;
      border: 1px solid var(--primary, #ff9500);
    }
    & .best_seller_wrapper {
      display: flex;
      gap: 1rem;
      & h3 {
        color: #000;
        font-family: Gilroy;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      & button {
        width: 2.5rem;
        height: 1.3125rem;
        flex-shrink: 0;
        border-radius: 2.5rem;
        border: none;
        background: #fff;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
      }
      & span {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 1px solid var(--primary, #ff9500);
        position: absolute;
        transition: 0.25s all ease-in-out;
        z-index: 1;
        background-color: white;
      }
      & .bestseller_notActive {
        left: 3px;
      }
      & .bestsellerActive {
        left: 1.3rem;
      }
      & .toggle_bar {
        height: 0.7rem;
        width: 1.8rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1rem;
      }
      & .bestseller_toggle_bar_notActive {
        background-color: rgb(195, 195, 195);
      }
      & .bestseller_toggle_bar_Active {
        background-color: var(--primary, #ff9500);
      }
    }
    & .upload_image_wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      & label {
        cursor: pointer;
        max-width: 42.1875rem;
        width: 100%;
        height: 8.75rem;
        flex-shrink: 0;
        border-radius: 0.625rem;
        border: 1px solid rgba(228, 201, 201, 0.8);
        background: #fff;
        position: relative;
        & input {
          opacity: 0;
        }
        & .submit_wrapper {
          display: flex;
          flex-direction: column;
          position: absolute;
          align-items: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          gap: 0.5rem;
          width: 100%w;
          & img {
            width: 3.75rem;
            height: 2.8125rem;
          }
          p {
            text-align: center;
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
            font-family: Gilroy;
            font-size: 0.625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 0.9375rem;
          }
        }
      }
    }
    & .button_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      & button {
        display: flex;
        width: 9.1875rem;
        height: 2.375rem;
        padding: 0.6875rem 1.8125rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border: none;
        flex-shrink: 0;
        text-align: center;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1rem;
        cursor: pointer;
        &:first-child {
          color: var(--primary, #ff9500);
          border-radius: 0.625rem;
          border: 1px solid var(--primary, #ff9500);
          background: #fff;
        }
        &:last-child {
          border-radius: 0.625rem;
          color: #fff;
          background: var(--primary, #ff9500);
          border: 1px solid var(--primary, #ff9500);
        }
      }
    }
  }
  & .addItem_oppner_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    padding: 1rem;
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: 0px;
    z-index: 1;
    & .addItem_oppner {
      height: fit-content;
      width: 100%;
      border-radius: 0.625rem;
      background: #fff;
      padding: 1rem;
      max-width: 21.5625rem;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.overflowNone {
  overflow-y: hidden;
  & .button_wrapper {
    display: flex;
    justify-content: center;
    gap: 1rem;
    & button {
      display: flex;
      width: 8.5rem;
      padding: 0.6875rem 1.3125rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      text-align: center;
      font-family: Gilroy;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      &:first-child {
        border-radius: 0.625rem;
        border: 1px solid var(--primary, #ff9500);
        background: #fff;
        color: var(--primary, #ff9500);
      }
      &:last-child {
        border-radius: 0.625rem;
        background: var(--primary, #ff9500);
        border: none;
        color: #fff;
      }
    }
  }
  & p {
    margin-bottom: 1rem;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.file_menu {
  cursor: pointer;
  max-width: 42.1875rem;
  width: 100%;
  height: 8.75rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  border: 1px solid rgba(228, 201, 201, 0.8);
  background: #fff;
  position: relative;
  & input {
    opacity: 0;
  }
  & .submit_wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 0.5rem;
    width: 100%w;
    & img {
      width: 3.75rem;
      height: 2.8125rem;
    }
    p {
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      font-family: Gilroy;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 500;
      line-height: 0.9375rem;
    }
  }
}

.cursorPointer {
  cursor: pointer;
}
