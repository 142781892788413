@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.AdminIndex_main_container_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
.AdminIndex_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0px 20px;
  max-height: 100dvh;
  // padding: 20px;
  // padding: 1rem 5rem;
  @include media('<=phone') {
    // padding: 1.5rem;
    // padding: 20px;
    padding: 0px;
  }
}
