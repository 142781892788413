@import '../../utils/variables.module';

.menuIndex_main_wrapper {
  display: flex;
  flex-direction: column;
  & header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    & .header_partOne {
      display: flex;
      gap: 1rem;
      align-items: center;
      overflow-x: auto;
      & h3 {
        color: #000;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      & span {
        display: flex;
        color: #000;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        align-items: center;
        gap: 0.5rem;
        position: relative;
        & ul {
          z-index: 1;
          position: absolute;
          top: 2rem;
          right: 0px;
          list-style-type: none;
          width: 9.5625rem;
          height: 7.5rem;
          flex-shrink: 0;
          border-radius: 0.625rem;
          background: #000;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: #fff;
          & li {
            display: flex;
            gap: 0.2rem;
            font-size: 1rem;
            & label {
              display: flex;
              gap: 0.4rem;
              accent-color: var(--primary, #ff9500);
            }
          }
          & button {
            height: 1.875rem;
            width: 100%;
            border-radius: 0.3125rem;
            border: 1px solid var(--primary, #ff9500);
            background: var(--primary, #ff9500);
            color: #fff;
            font-family: Gilroy;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
          }
        }
        & .filterList {
          width: 11.5625rem;
          height: 7.5rem;
          z-index: 2;
        }
        & img {
          width: 1rem;
          height: 1rem;
          flex-shrink: 0;
        }
      }
    }
  }
}

.filters {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-right: 10px;
}

.filter_box {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #afa8a8;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  svg {
    height: 15px;
    width: 15px;
  }
  .downArrow {
    height: 10px;
  }
}

.readyToAddFiler {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  // overflow-x: auto;
}

.single_filter_box {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #afa8a8;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  width: max-content;
  position: relative;
  svg {
    height: 12px;
    width: 12px;
  }
  .downArrow {
    height: 10px;
  }
}

.filterBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary, #ff9500);
  opacity: 0.1;
  z-index: -1;
}

// hide scrollbar
.header_partOne::-webkit-scrollbar {
  display: none;
}

.closeIcon {
  stroke: red;
}

.mobileFilter {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktopFilter {
    display: none;
  }

  .mobileFilter {
    display: block;
  }
}
