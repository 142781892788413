@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.categoryItem_main_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  // flex: 1;
  padding: 1rem;
  & .categoryItem_details {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    & span {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      // background: #ebebeb;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 60px;
        height: 60px;
        border-radius: 5px;
      }
    }
  }
}

.categoryItem_menu_list {
  display: flex;
  gap: 1.5rem;
  @include media('<=phone') {
    gap: 0.5rem;
  }
  & img {
    height: 1.7rem;
  }
  & .categoryItem_menu_list_ul {
    display: flex;
    gap: 1rem;

    // display: none;

    @include media('<=phone') {
      gap: 0.5rem;
    }
    // add animation
  }
}

.opening_popper {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.394);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.innerPopper {
  width: 382px;
  border-radius: 10px;
  background-color: white;
  position: relative;
  z-index: 1;
}

.innerPopper_padding {
  // padding: 1.5rem;
  // padding-top: 2.3rem;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & svg {
    height: 20px;
    float: right;
    align-self: flex-end;
  }
}

.button_wrapper_edit {
  display: flex;
  justify-content: center;
  gap: 1rem;
  & button {
    height: 2.75rem;
    width: 8rem;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    &:last-child {
      background-color: #f55757;

      color: white;
    }
    &:first-child {
      border: 1px solid var(--primary, #ff9500);
      color: var(--primary, #ff9500);
      background-color: white;
    }
  }
}

.cursorPointer {
  cursor: pointer;
}
