@import '../../styles/utils/breakPoints.module.scss';
@import '../../styles/utils/variables.module';

.searchComponent_main {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.5rem;

  & .searchComp_label {
    border: 1px solid var(--primary, #ff9500);
    background: rgba(255, 255, 255, 0.24);
    border-radius: 0.625rem;
    max-width: 39.75rem;
    width: 100%;
    display: flex;
    padding: 0rem 1rem;
    gap: 0.5rem;
    height: 2.5rem;
    align-items: center;
  }
  & input {
    display: flex;
    background: transparent;
    flex: 1;
    height: 100%;
    border: none;
    outline: 0px;
  }
  & img {
    width: 1.2rem;
    height: 1.2rem;
  }

  @include media('<=phone') {
    // padding: 0px 20px;
  }
}
