@import '../utils/variables.module';

.search_main_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  & h3 {
    font-weight: 400;
    font-size: 1rem;
  }
}
.main_wrapper {
  display: flex;
  padding: 1rem;
  width: 100%;
  gap: 1rem;
  align-items: center;
  border-bottom: 1px solid rgb(232, 232, 232);
}

.img_wrapper {
  width: 3.75rem;
  height: 3.75rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  background: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
}
