@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.order_page_index_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 3rem;
  flex: 1;
  // @include media('<=phone') {
  //   padding: 1.3rem;
  // }
}
.orderHeader_main {
  display: flex;
  width: 100%;
  position: relative;
  gap: 0.5rem;
  align-items: center;
  height: fit-content;
  margin-bottom: 1.3rem;
  & .backArrow {
    width: 0.5625rem;
    height: 1rem;
    flex-shrink: 0;
    cursor: pointer;
  }
  & .history_wrapper {
    position: absolute;
    right: 0rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    & span {
      color: #000;
      text-align: right;
      leading-trim: both;
      text-edge: cap;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.horizontal_rulling {
  margin-top: 0.75rem;
  min-height: 0.5px;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.order_index_animation_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  .progressBar_wrapper {
    height: 0.5rem;
    width: 20rem;
    border-radius: 0.94225rem;
    background: #f3f3f3;
    overflow: hidden;
    display: flex;

    & div {
      height: 0.5rem;
      width: 0%;
      border-radius: 0.94225rem;
      background: #f19330;
      animation: progressBar_animation 2.9s forwards;
    }
    @keyframes progressBar_animation {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
}
