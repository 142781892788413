@import '../../utils/breakPoints.module.scss';
@import '../../utils/variables.module';

.ItemDetails_main {
  display: flex;
  position: absolute;
  height: 100%;
  // min-height: 100dvh;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 100;
  flex-direction: column;
  height: 100%;
  background: #fff;
  overflow: auto;
  & .horizontal_bar {
    position: absolute;
    top: 1rem;
    width: 4.75rem;
    height: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: #b3b3b3;
    left: 50%;
    transform: translateX(-50%);
  }
  & .image_icon {
    width: 100%;
    max-height: 400px;
    // height: 38.875rem;
    border-radius: 10px 10px 0px 0px;
    flex-shrink: 0;
    @include media('<=phone') {
      height: 14.8125rem;
    }
  }
  & .img_wrapper {
    display: flex;
    position: relative;
  }
  & .backArrowWhite {
    position: absolute;
    top: 2rem;
    left: 2rem;
    cursor: pointer;
  }
  & .ItemDetails_wrapper {
    flex: 1;
    border-radius: 1.875rem 1.875rem 0rem 0rem;
    background: #fff;
    padding: 4.5rem;
    padding-top: 6rem;
    transform: translateY(-2rem);
    display: flex;
    flex-direction: column;
    @include media('<=phone') {
      padding: 2rem;
      padding-top: 2.5rem;
    }
    & h2 {
      color: #000;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    & .nameAndPrice {
      display: flex;
      // justify-content: space-between;
      margin-top: 1rem;
      gap: 10px;
    }
    & p {
      color: #000;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 0.875rem;
      margin-top: 0.5rem;
    }
    & .horizontal_rulling {
      min-height: 0.5px;
      min-width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  & .item_cart_bar {
    display: flex;
    justify-content: center;
    align-items: center;
    // transform: translate(0rem, -3rem);
    margin-top: -3rem;
    gap: 1rem;
    margin-bottom: 2rem;
    z-index: 100;
    & span {
      color: #727272;
      font-family: Gilroy;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    & .quantity_button_wrapper {
      width: 7.8125rem;
      height: 2.125rem;
      border-radius: 0.625rem;
      border: 1px solid #c5c5c5;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      & button {
        all: unset;
        color: #000;
        font-family: Gilroy;
        font-size: 2rem;
        font-style: normal;
        font-weight: 500;
        height: fit-content;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }
      & span {
        color: #000;
        font-family: Gilroy;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    & .addToCart_button {
      display: inline-flex;
      padding: 0.625rem 1.4375rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      border-radius: 0.625rem;
      border: 1px solid var(--primary, #ff9500);
      background: var(--primary, #ff9500);
      color: #fff;
      text-align: center;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
}

.item_details_checkBox_container_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.item_details_checkBox_container {
  display: flex;
  // margin: 0.7rem 0rem;
  justify-content: space-between;
  gap: 10px;
  & span {
    display: flex;
    align-items: center;
    gap: 1rem;
    & input {
      accent-color: var(--primary, #ff9500);
    }
  }
}

.add_new_item {
  display: flex;
  // position: absolute;
  bottom: 0rem;
  height: 5rem;
  width: 100%;
  background-color: var(--primary, #ff9500);
  animation: fade_in_out 3s ease-in-out forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gilroy;
  color: white;
  font-size: 1.2rem;
}

@keyframes fade_in_out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.price_container_item_details {
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  margin-top: 1rem;
  & p {
    margin: none;
    margin-bottom: 1rem;
    font-size: 1rem !important;
  }
}

.price_container_item_details label {
  display: flex;
  justify-content: space-between;
  & span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    & input {
      opacity: 0;
      width: 0px;
    }
    & .circle_wrapper {
      height: 1.3rem;
      width: 1.3rem;
      background-color: transparent;
      border-radius: 50%;
      border: 2px solid #b8b8b8;
    }
    // & .circle_wrapper_active {
    //   height: 1.3rem;
    //   width: 1.3rem;
    //   background-color: var(--primary, #ff9500);
    //   border-radius: 50%;
    //   border: 2px solid #ffffff;
    //   outline: 1px solid var(--primary, #ff9500);
    // }
  }
}

.price_container_item_details_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.cart_footer_wrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  background-color: var(--primary, #ff9500);
  bottom: 50px;
  right: 50px;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  @include media('<=phone') {
    z-index: 100;
    bottom: 100px;
  }
  & img {
    width: 30px;
  }
  .cartNumber {
    position: absolute;
    color: white;
    right: 20px;
  }
}
