@import '../utils/breakPoints.module.scss';
@import '../utils/variables.module';

.adminTablesPage_main {
  display: flex;
  flex-direction: column;
  width: 100%;
  & header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    & h3 {
      color: #000;
      font-family: Gilroy;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: grid;
      place-items: center;
    }
    & span {
      display: flex;
      gap: 0.3rem;
      align-items: center;
      color: #000;
      font-family: Gilroy;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      & .AddItem_button {
        display: flex;
        bottom: 4rem;
        right: 2rem;
        align-items: center;
        gap: 0.4rem;
        margin-left: 1rem;
        & button {
          display: flex !important;
          height: 2.375rem !important;
          // padding: 0.1875rem 1.1875rem;
          padding: 10px 15px !important;
          align-items: center;
          gap: 0.5rem !important;
          border-radius: 0.625rem;
          background: var(--primary, #ff9500) !important;
          color: #fff;
          text-align: center;
          font-family: Gilroy;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}
